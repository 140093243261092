import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Cap1Disclosure from "src/components/cap1disclosure"
import Footerlinks from "src/components/footerlinks"

const prdbestData = import("./data/data.json");

export default function prdbest() {

  let css;
       if(isChrome()){
         css = `
         `;
       }

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background: #001c20;
    }

    .footerlinks-module--br,
     .footerlinks-module--br a {
      color:#f1f1f1;
    }

    .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold!important;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }
    .trbm-module--trbm button {
      background: #43ba19;
    }

    .trbm-module--trbm .trbm-module--bullets {
      width: 80%;
      line-height: 150%;
      list-style: none;
    }

    .trbm-module--trbm .trbm-module--bullets li {
      text-align: center;
    }

    .trbm-module--trbm .trbm-module--steps {
      display: none;
    }

    .trbm-module--trbm .trbm-module--card {
      margin: 10% auto 0;
    }

    .footerlinks-module--br {
      display: none!important;
    }

    .styles-module--footerlinks {
      background: none;
      position: fixed;
      bottom: 10px;
      padding: 0;
      left: 50%;
    transform: translateX(-50%);
    }

    .styles-module--footerlinks a {
      color: rgba(255, 255, 255, 0.5)!important;
    }

    .trbm-module--trbm .trbm-module--logo {
      top: 0;
      left: 20px;
      position: absolute;
    }

    .trbm-module--trbm h2 {
      font-weight: lighter;
    }

    .trbm-module--trbm h1 {
      font-weight: bold;
      margin: 20px 0 0;
    }
    `}
    </style>
    <style type="text/css">
     {css}
         </style>
    <title>Automatically Apply Coupons - ShopGenius</title></Helmet>
    <section>
      <Trbm data={prdbestData}>
      <Cap1Disclosure></Cap1Disclosure>
    </Trbm>
    <Footerlinks></Footerlinks>
      </section>
    </HomepageLayout>
  )
}
