import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/simp.module.scss"

class Cap1Disclosure extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }

    componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
    if (typeof window != "undefined") {
        window.mapParams();
    }
  }
  render() {
    return (
        <div id = {Styles.simpdisclosure}>
        <div className={Styles.disclosure}>
        <p>This page is sponsored by Capital One Shopping. By continuing, you acknowledge the <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a>, and may be presented with additional optional offers.</p>
          </div>
        </div>
    )
  }
}
export default Cap1Disclosure
